<template>
  <div class='header-text'>
    <sc-text :tag="params.htag" :field="fields.heading" />
    <sc-content :field="fields.content" />
  </div>
</template>

<script>
import { RichText } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'HeaderText',
  components: {
    ScText: RichText,
    ScContent: RichText,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
    },
  },
}
</script>
