<template>
  <div>
    <p>ClientsAndPartners Component</p>
    <sc-text :field="fields.heading" />
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'ClientsAndPartners',
  components: {
    ScText: Text,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
