<template>
    <div>
		<sc-text tag="h2" :field="fields.title" />
			<ul class="team-members">
				 <!--
				   When the page is editing, we hide the tab headings, because they are not updated when xEditor adds or removes a tab rendering.
				   Instead, we show the tab header inline with the tab contents (see Styleguide-Layout-Tabs-Tab).
				 -->
				<div id="inner-wrap" class="inner-wrap">
					<component v-for="(teamMember, index) in $options.computed.teamPlaceholder" :id="`team-member-${index}`" :key="`teamMember${index}`" :is="teamMember" />

				</div>
			</ul>
			<div class="pagination">
				<span class="dot" v-for="(teamMember, index) in $options.computed.teamPlaceholder" v-bind:key="index" v-bind:class="{ selected: selectedIndex == index }" @click="scrollIntoView($event, index)">
			</span>
		</div>
	</div>
</template>

<script>
import { Placeholder, Text } from '@sitecore-jss/sitecore-jss-vue';
import $ from 'jquery';

export default {
  name: 'TeamMembersContainer',
  mounted() {
      //console.log(this.teamPlaceholder);
      var vm = this;
      let totalTeamMembers = this.$options.computed.teamPlaceholder.length;
      let touchstartX = 0;
      let touchstartY = 0;
      let touchendX = 0;
      let touchendY = 0;

       const gestureZone = $(vm.$el).find('.inner-wrap')[0];



      // const gestureZone = document.getElementById('inner-wrap');

      gestureZone.addEventListener('touchstart', function(event) {
          touchstartX = event.changedTouches[0].screenX;
          touchstartY = event.changedTouches[0].screenY;
      }, false);

      gestureZone.addEventListener('touchend', function(event) {
          touchendX = event.changedTouches[0].screenX;
          touchendY = event.changedTouches[0].screenY;
          handleGesture(event);
      }, false);

      function handleGesture(event) {
          if (touchendX < touchstartX && Math.abs(touchendX - touchstartX) > 50) {
              if(vm.selectedIndex !== totalTeamMembers - 1) {
                  vm.selectedIndex++;
                  vm.scrollIntoView(event, vm.selectedIndex);

              }
          }
          if (touchendX > touchstartX && Math.abs(touchendX - touchstartX) > 50) {
              if(vm.selectedIndex !== 0) {
                  vm.selectedIndex--;
                  vm.scrollIntoView(event, vm.selectedIndex)

              }
          }
      }
  },
  data: function() {
    return {
        selectedIndex: 0
    }
  },
  components: {
      ScText: Text
  },
  props: {
    rendering: {
      type: Object,
  },
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  withPlaceholder() {
    return {
	  placeholders: [
        {
          placeholder: 'team-members',
          computedPropName: 'teamPlaceholder',
        },
      ],
	};
  },
  methods: {
      scrollIntoView: function(event, index) {
          this.selectedIndex = index;
          //console.log(event.target);
          var innerWrap = $(event.target).closest('.inner-wrap');
          if(!innerWrap.length) {
              innerWrap = $(event.target).parents('.pagination').siblings('.team-members').children('.inner-wrap');
          }
          var scrollTo = innerWrap.children('li')[index];
          innerWrap.animate({scrollLeft: scrollTo.offsetLeft - 20}, 800);
      }
  },
  computed: {

   //
   // isEditing() {
   //   // this.$jss is defined on the Vue instance by the SitecoreJssPlugin and provides
   //   // reactive access to the `sitecoreContext` provided in layout service data.
   //   return this.$jss.sitecoreContext().pageEditing;
   // },
   // activeTab() {
   //   return this.teamPlaceholder;
   // },
 },
}
</script>
