<template>
    <div class="wrapper">
        <div class="row5050">
            <div class="left" :style="params.leftStyle">
                <div><placeholder name="row-5050-left" :rendering="rendering" /></div>
            </div>
            <div class="right" :style="params.rightStyle">
                <div><placeholder name="row-5050-right" :rendering="rendering" /></div>
            </div>
        </div>
    </div>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'Row5050',
  components: {
      Placeholder,
  },
  props: {
    rendering: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
    },
  },
}
</script>

<style scoped>

    .left {
        text-align: left;

    }
    .right {

    }
    .row5050 {
        display: flex;
        width: 100%;
    }
</style>
