<template>
  <div id="calendar-row">
      <div class="wrapper">
        <div class="inside-wrap">

            <div class="info">
                <div><placeholder name="links-placeholder" :rendering="rendering" /></div>
            </div>
          <ul>
              <li v-bind:class="{ deadline: event.is_deadline, distribution: event.isDistribution }" v-for="(event, index) in events" v-bind:key="index">
                  <h4 class="display-date">{{event.humanDate}}</h4>
                  <span v-if="event.is_deadline" class="type">DEADLINE
                      <img :src="deadlineIcon">
                  </span>
                  <span v-if="event.is_distribution" class="type">DISTRIBUTION
                      <img :src="distributionIcon">
                  </span>
                  <p v-if="event.subtitle" class="subtitle">{{event.subtitle}}</p>

                  <p>{{event.title}} <span class="teal-text" v-if="event.quarterYear">{{event.quarterYear}}</span></p>
              </li>
               <div class="ribbon-line-inside"></div>
          </ul>
      </div>
  </div>
  <div class="ribbon-line"><div class="ribbon-line-start"></div><div class="ribbon-line-end"></div></div>
  </div>
</template>

<script>

import { Text, RichText, Placeholder } from '@sitecore-jss/sitecore-jss-vue';

import axios from "axios";
import moment from "moment";

import deadlineIcon from '../assets/deadline.svg';
import distributionIcon from '../assets/distribution.svg';


export default {
  name: 'SwCalendarRow',
  components: {
    ScText: Text,
    Placeholder,
  },
  props: {
      rendering: {
        type: Object,
        default: () => ({}),
      },
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data: function() {
    return {
        events: null,
        deadlineIcon,
        distributionIcon,
    }
  },
  mounted () {
    axios
      .get(`/api/calendar/calendar`)

      .then((res) => {
          var newArray = [];
          for (var i = 0; i < res.data[0].quarters.length; i++) {
              var newArray = newArray.concat(res.data[0].quarters[i].events);
          }
          var events = newArray.filter(event => moment(event.start_date).isAfter(new Date()));
          events = events.slice(0,3).map(event => {
              event.humanDate = event.display_date || moment(event.start_date).format('MMMM D');
              // if(event.is_distribution) {
              //     var quarterYear = event.content.match(/\(([^)]+)\)/)[1]
              //     if(quarterYear) {
              //         var q = quarterYear.slice(0, 2)
              //         console.log(q);
              //         q = q.split("").reverse().join("");
              //
              //         var y = "20" + quarterYear.slice(2, 4);
              //         console.log("YEAR",y);
              //         quarterYear = `[${q} ${y}]`
              //         event.quarterYear = quarterYear;
              //     }
              // }
              return event;
          });
          this.events = events;
      })
  }

}
</script>
