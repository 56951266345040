<template>
<li class="team-member">
  <div v-bind:class="{ open: isOpen  }" class="team-member-component">
      <div class="frame">
          <sc-image class="img"
            :media="fields.image"
          />
          <div class="corners-bottom"></div>
      </div>
    <sc-text class="name" :field="fields.name" />
    <sc-text class="role" :field="fields.role" />
    <sc-rich-text class="bio" :field="fields.description" />
    <a v-if="!isOpen" @click="toggleOpen" class="toggle-show">+ More</a>
    <a v-else @click="toggleOpen" class="toggle-show">- Less</a>
  </div>
</li>
</template>

<script>
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-vue';
import $ from 'jquery';

export default {
  name: 'TeamMember',
  components: {
    ScText: Text,
    ScImage: Image,
    ScRichText: RichText,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data: function() {
    return {
        isOpen: false
    }
  },
   methods: {
       toggleOpen: function(e) {
           if(this.isOpen) {
               $(e.target).siblings('.bio').slideUp();
           } else {
               $(e.target).siblings('.bio').slideDown();
           }
           this.isOpen = !this.isOpen;

       }
   }
}
</script>
