<template>
    <div id="calendar" class="wrapper">
        <div class="calendar-header" v-bind:class="{extended_header: startingQuarter == 4}">
            <!-- add extended-header class if we also need to display nextYear -->
            <div>
                <h2 class="year" v-if="year">{{year}}</h2>
                <div class="quarter-buttons">
                    <span @click="changeQuarter(1)"  v-bind:class="{ active: (1 == activeQuarter) }" class="quarter-btn">Q1</span>
                    <span @click="changeQuarter(2)"  v-bind:class="{ active: (2 == activeQuarter) }" class="quarter-btn">Q2</span>
                    <span @click="changeQuarter(3)"  v-bind:class="{ active: (3 == activeQuarter) }" class="quarter-btn">Q3</span>
                    <span @click="changeQuarter(4)"  v-bind:class="{ active: (4 == activeQuarter) }" class="quarter-btn">Q4</span>

                </div>
                <h2 class="year nextYear" v-if="nextYear && !isMobile && quarters.length == 5">{{nextYear}}</h2>
                <div class="quarter-buttons" v-if="nextYear && !isMobile && quarters.length == 5">
                    <span @click="changeQuarter(5)"  v-bind:class="{ active: (5 == activeQuarter) }" class="quarter-btn">Q1</span>
                </div>

            </div>
            <hr class="quarter-underline "/>
        </div>

            <span v-for="(c, key) in categoryStructure" v-bind:key="key" v-if="isMobile">
                <div class="categoryButton" @click="toggleCategoryExpand($event, key)" v-bind:class="{hasPassed: c.hasPassed}">{{key}}
                    <svg v-if="c.expanded" height="3" viewBox="0 0 15 3" width="15" xmlns="http://www.w3.org/2000/svg"><path d="m6.5 0h2v15h-2z" fill="#fff" fill-rule="evenodd" transform="matrix(0 1 -1 0 15 -6)"/></svg>
                    <svg v-else height="15" viewBox="0 0 15 15" width="15" xmlns="http://www.w3.org/2000/svg"><path d="m8.5 0v6.499l6.5.001v2h-6.5v6.5h-2v-6.5h-6.5v-2h6.5v-6.5z" fill="#fff" fill-rule="evenodd"/></svg>
                </div>
                <ul class="mobile-cal" v-bind:class="{expanded: c.expanded}">
                    <li v-for="(event, i) in c.events" v-bind:key="i" v-bind:class="{ has_passed: event.hasPassed, deadline: event.is_deadline, distribution: event.is_distribution }" >
                        <h4 class="display-date"  v-bind:class="{full_opacity: !shouldAnimate}">{{event.humanDate}}</h4>
                        <span v-if="event.is_deadline" class="type">DEADLINE
                            <img v-if="!event.hasPassed" :src="deadlineIcon">
                            <img v-else :src="deadlinePassedIcon">
                        </span>
                        <span v-if="event.is_distribution" class="type">DISTRIBUTION
                            <img v-if="!event.hasPassed" :src="distributionIcon">
                            <img v-else :src="distributionPassedIcon">
                        </span>
                        <p v-if="event.subtitle" class="subtitle">{{event.subtitle}}</p>
                        <p>{{event.title}} <span class="teal-text" v-if="event.quarterYear">{{event.quarterYear}}</span></p>
                    </li>
                </ul>
            </span>
            <div class="next-year-header-mobile" v-if="isMobile && activeQuarter == 4 && quarters.length == 5">
                <h2 class="year nextYear" v-if="nextYear">{{nextYear}}</h2>
                <div class="quarter-buttons">
                    <span class="quarter-btn">Q1</span>
                </div>
            </div>


            <span v-for="(c, key) in nextYearCategoryStructure" v-bind:key="key" v-if="isMobile && activeQuarter == 4">
                <div class="categoryButton" @click="toggleNextYearCategoryExpand($event, key)" v-bind:class="{hasPassed: c.hasPassed}">{{key}}
                    <svg v-if="c.expanded" height="3" viewBox="0 0 15 3" width="15" xmlns="http://www.w3.org/2000/svg"><path d="m6.5 0h2v15h-2z" fill="#fff" fill-rule="evenodd" transform="matrix(0 1 -1 0 15 -6)"/></svg>
                    <svg v-else height="15" viewBox="0 0 15 15" width="15" xmlns="http://www.w3.org/2000/svg"><path d="m8.5 0v6.499l6.5.001v2h-6.5v6.5h-2v-6.5h-6.5v-2h6.5v-6.5z" fill="#fff" fill-rule="evenodd"/></svg>
                </div>
                <ul class="mobile-cal" v-bind:class="{expanded: c.expanded}">
                    <li v-for="(event, i) in c.events" v-bind:key="i" v-bind:class="{ has_passed: event.hasPassed, deadline: event.is_deadline, distribution: event.is_distribution }" >
                        <h4 class="display-date"  v-bind:class="{full_opacity: !shouldAnimate}">{{event.humanDate}}</h4>
                        <span v-if="event.is_deadline" class="type">DEADLINE
                            <img v-if="!event.hasPassed" :src="deadlineIcon">
                            <img v-else :src="deadlinePassedIcon">
                        </span>
                        <span v-if="event.is_distribution" class="type">DISTRIBUTION
                            <img v-if="!event.hasPassed" :src="distributionIcon">
                            <img v-else :src="distributionPassedIcon">
                        </span>
                        <p v-if="event.subtitle" class="subtitle">{{event.subtitle}}</p>
                        <p>{{event.title}} <span class="teal-text" v-if="event.quarterYear">{{event.quarterYear}}</span></p>
                    </li>
                </ul>
            </span>



        <ul v-for="(e, index) in Math.ceil(events.length / maxRows)" v-bind:key="index" v-if="!isMobile">
            <li v-bind:class="{ has_passed: event.hasPassed, deadline: event.is_deadline, distribution: event.is_distribution }" v-for="(event, i) in events.slice((e - 1) * maxRows, e * maxRows)" v-bind:key="i">
                <h4 class="display-date"  v-bind:class="{full_opacity: !shouldAnimate}">{{event.humanDate}}</h4>
                <span v-if="event.is_deadline" class="type">DEADLINE
                    <img v-if="!event.hasPassed" :src="deadlineIcon">
                    <img v-else :src="deadlinePassedIcon">
                </span>
                <span v-if="event.is_distribution" class="type">DISTRIBUTION
                    <img v-if="!event.hasPassed" :src="distributionIcon">
                    <img v-else :src="distributionPassedIcon">
                </span>
                <p v-if="event.subtitle" class="subtitle">{{event.subtitle}}</p>
                <p>{{event.title}} <span class="teal-text" v-if="event.quarterYear">{{event.quarterYear}}</span></p>
                <div class="bar" v-bind:class="{has_passed: categoryStructure[event.category].hasPassed}"></div>
                <div class="bar-combiner" v-bind:class="{has_passed: categoryStructure[event.category].hasPassed}" v-if="i !== 0 && !event.isStart"></div>
                <div v-bind:class="{has_passed: categoryStructure[event.category].hasPassed}" class="category" v-if="i == 0 || event.isStart">{{event.category}}</div>
            </li>
            <!-- Fills out the grid with empty cells for formatting -->
            <li class="empty" v-if="index == Math.ceil(events.length / maxRows) - 1 && (Math.ceil(events.length / maxRows) - 1) && maxRows - Math.ceil(events.length % maxRows) !== maxRows" v-for="x in maxRows - Math.ceil(events.length % maxRows)" v-bind:key="x">
                <a class="next-quarter-btn" v-if="activeQuarter !== quarters.length && x == 1" @click="changeQuarter(activeQuarter+1)">Next quarter</a>
            </li>
        </ul>
        <div v-if="isMobile">
            <a v-if="showExpandAll" @click="expandAll()">Expand all sections</a>
            <a v-else @click="collapseAll()">Collapse all sections</a>
        </div>

    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import $ from "jquery";

import deadlineIcon from '../assets/deadline.svg';
import deadlinePassedIcon from '../assets/deadline-passed.svg';
import distributionIcon from '../assets/distribution.svg';
import distributionPassedIcon from '../assets/distribution-passed.svg';

export default {
  name: 'SwCalendarRow',
  data: function() {
    return {
        events: [],
        categoryStructure: {},
        nextYearCategoryStructure: {},
        quarters: [],
        activeQuarter: 1,
        year: null,
        nextYear: null,
        showNextYear: false,
        nextYearEvents: null,
        isMobile: false,
        maxRows: 4,
        shouldAnimate: false,
        showExpandAll: true,
        deadlineIcon,
        deadlinePassedIcon,
        distributionIcon,
        distributionPassedIcon,
        startingQuarter: 1,
    }
  },
  mounted () {
    axios
      .get(`/api/calendar/calendar`)
      .then((res) => {

          this.quarters = res.data[0].quarters;

          if(res.data.length > 1) {
              this.quarters.push(res.data[1].quarters[0]);
          }


          this.year = res.data[0].year;
          this.nextYear = parseInt(this.year)+1;

          // get active quarter by checking if the last date in quarter is after today's date
          for (var i = 0; i < this.quarters.length; i++) {
              this.activeQuarter = i+1;
              var lastEvent = this.quarters[i].events[this.quarters[i].events.length - 1];
              var isAfter = moment(lastEvent.start_date).isAfter(new Date());
              if(isAfter) break;
          }

          // used for testing
          //this.activeQuarter = 4;

          this.startingQuarter = this.activeQuarter;

          // remove next year if we're not starting in quarter 4
          if(this.startingQuarter !== 4 && this.quarters.length == 5) {
              this.quarters.pop();
          }

          this.events = this.structureEvents(this.quarters[this.activeQuarter-1].events);
          if(this.activeQuarter === 4 && this.quarters.length === 5) {
              this.nextYearEvents = this.structureNextYearEvents(this.quarters[4].events);
          }



          setTimeout(() => {
            // show expanded by default
            $('.expanded').css('opacity', 1).show();

            // position underline
            this.positionUnderline();
            this.addDateToPdf();
          }, 0);


      });
      // type check to prevent error during SSR
      if(typeof window !== 'undefined') {
          // Detect if we're on mobile on creation
          this.isMobile  = window.matchMedia("(max-width: 850px)").matches;
           this.maxRows = window.matchMedia("(max-width: 1200px)").matches ? 3 : 4;

          // Watch for changes in screen size and set mobile
          window.onresize = () => {
              this.shouldAnimate = false; // prevents oopacity issue on mobile
              this.isMobile = window.matchMedia("(max-width: 850px)").matches;
              // If mobile menu is open and we switch to desktop, close menu
              if(this.isOpen && !this.isMobile) {
                  this.isOpen = false;
              }
              this.maxRows = window.matchMedia("(max-width: 1200px)").matches ? 3 : 4;
              this.positionUnderline();
          };
      }
  },
  methods: {
      addDateToPdf() {
          var lastUpdated = encodeURI($('time.last-updated').text());
          $('a.download-pdf').attr('href', '/api/calendar/generatepdf?lastUpdated='+lastUpdated);
      },
      positionUnderline() {

          if(this.isMobile && this.activeQuarter === 5) {
              this.changeQuarter(4);
          }

          $('.quarter-underline').width($('.quarter-btn.active').width());
          $('.quarter-underline').css("width", left+'px');
          // var offsetFix = this.isMobile ? 20 : 80;

          var parentPos = $('.quarter-btn.active').parent().position().left;
          var left = $('.quarter-btn.active').position().left + parentPos;
          $('.quarter-underline').css("margin-left", left+'px');
      },
      generatePdfMessage() {
        var toolTip = $('.download-tooltip');
        var originalText = toolTip.text();
        toolTip.addClass('always-show');
        toolTip.text('Generating PDF...');
        setTimeout(function () {
            toolTip.text(originalText);
            toolTip.removeClass('always-show');
        }, 10000);

      },
      determineExpandCollapse() {
          // determines whether
          var shouldExpand = false;
          for (const category in this.categoryStructure) {
              if(!this.categoryStructure[category].expanded) {
                  shouldExpand = true;
                  break;
              }
          }
          this.showExpandAll = shouldExpand;
      },
      expandAll() {
          for (const category in this.categoryStructure) {
              this.categoryStructure[category].expanded = true;
          }
          var newStructure = this.categoryStructure;
          this.categoryStructure = {};
          this.categoryStructure = newStructure;
          setTimeout(function () {
              $('.mobile-cal').css('opacity', 0)
            .slideDown('slow');
            // jquery .delay() isn't working here, using timeout instead
            setTimeout(function () {
                $('.mobile-cal').animate(
                  { opacity: 1 },
                  { queue: false, duration: 'slow' }
                );
            }, 500);
          }, 0);

          this.showExpandAll = false;

      },
      collapseAll() {
          for (const category in this.categoryStructure) {
              this.categoryStructure[category].expanded = false;
          }
          var newStructure = this.categoryStructure;
          this.categoryStructure = {};
          this.categoryStructure = newStructure;
          setTimeout(function () {
              $('.mobile-cal').animate(
               { opacity: 0 },
               { queue: false, duration: 'fast' }
           ).slideUp('slow');
          }, 0);
          this.showExpandAll = true;

      },
      structureEvents(events) {
          this.categoryStructure = {};

          var nextEvent = null;

          var structuredEvents = events.map(event => {
              event.humanDate = event.display_date || moment(event.start_date).format('MMMM D');
              event.hasPassed = moment(event.start_date).isBefore(new Date())
              event.isEnd = false;
              event.isFirst = false;
              // if(event.is_distribution) {
              //     var quarterYear = event.content.match(/\(([^)]+)\)/)[1]
              //     if(quarterYear) {
              //         var q = quarterYear.slice(0, 2)
              //
              //         q = q.split("").reverse().join("");
              //         var y = "20" + quarterYear.slice(2, 4);
              //
              //         quarterYear = `[${q} ${y}]`
              //         event.quarterYear = quarterYear;
              //     }
              // }
              return event;
          });

          // TODO: Refactor this
          var itemsInRow = this.maxRows;

          var previousCategory = "Process"; // use to group in category if none exists
          for (var i = 0; i < events.length; i++) {
              events[i].category = events[i].category || previousCategory;

              previousCategory = events[i].category;
              if(typeof this.categoryStructure[events[i].category] == 'undefined') {
                  this.categoryStructure[events[i].category] = {};
                  this.categoryStructure[events[i].category].events = [];
                  this.categoryStructure[events[i].category].expanded = false;
              }
              this.categoryStructure[events[i].category].events.push(events[i]);

              if(!nextEvent && moment(events[i].start_date).isAfter(new Date())) {
                  nextEvent = events[i];
              }

              if(i < events.length-1) {
                  if(typeof events[i+1].category == 'undefined') {
                       events[i+1].category = events[i].category;
                  }
                  if(events[i].category !== events[i+1].category) {
                    events[i].isEnd = true;
                  }
              } else {
                  events[i].isEnd = true;
              }
              if(i > 0 && events[i].category !== events[i-1].category) {
                 events[i].isStart = true;
              }
          }

          if(nextEvent) {
              this.categoryStructure[nextEvent.category].expanded = true;
          }



          for(var key in this.categoryStructure) {
              var categoryEvents = this.categoryStructure[key].events;
              if(moment(categoryEvents[categoryEvents.length - 1].start_date).isBefore(new Date())){
                  this.categoryStructure[key].hasPassed = true;
              } else {
                  this.categoryStructure[key].hasPassed = false;
              }
          }
          return structuredEvents;
      },
      structureNextYearEvents(events) {
          this.nextYearCategoryStructure = {};

          var nextEvent = null;

          var structuredEvents = events.map(event => {
              event.humanDate = event.display_date || moment(event.start_date).format('MMMM D');
              event.hasPassed = moment(event.start_date).isBefore(new Date())
              event.isEnd = false;
              event.isFirst = false;
              // if(event.is_distribution) {
              //     var quarterYear = event.content.match(/\(([^)]+)\)/)[1]
              //     if(quarterYear) {
              //         var q = quarterYear.slice(0, 2)
              //
              //         q = q.split("").reverse().join("");
              //         var y = "20" + quarterYear.slice(2, 4);
              //
              //
              //         event.quarterYear = quarterYear;
              //     }
              // }
              return event;
          });

          // TODO: Refactor this
          var itemsInRow = this.maxRows;

          var previousCategory = "Process"; // use to group in category if none exists
          for (var i = 0; i < events.length; i++) {
              events[i].category = events[i].category || previousCategory;
              previousCategory = events[i].category;
              if(typeof this.nextYearCategoryStructure[events[i].category] == 'undefined') {
                  this.nextYearCategoryStructure[events[i].category] = {};
                  this.nextYearCategoryStructure[events[i].category].events = [];
                  this.nextYearCategoryStructure[events[i].category].expanded = false;
              }
              this.nextYearCategoryStructure[events[i].category].events.push(events[i]);



              if(i < events.length-1) {
                  if(typeof events[i+1].category == 'undefined') {

                       events[i+1].category = events[i].category;
                  }
                  if(events[i].category !== events[i+1].category) {

                    events[i].isEnd = true;
                  }
              } else {
                  events[i].isEnd = true;
              }
              if(i > 0) {

                  if(events[i].category !== events[i-1].category) {

                    events[i].isStart = true;
                  }
              }
          }



          for(var key in this.nextYearCategoryStructure) {
              var categoryEvents = this.nextYearCategoryStructure[key].events;
              if(moment(categoryEvents[categoryEvents.length - 1].start_date).isBefore(new Date())){
                  this.nextYearCategoryStructure[key].hasPassed = true;
              } else {
                  this.nextYearCategoryStructure[key].hasPassed = false;
              }
          }

          return structuredEvents;
      },
      changeQuarter: function(quarter) {

          $('.mobile-cal').hide();

          this.shouldAnimate = true;
          this.activeQuarter = quarter;
          var x = document.getElementsByClassName('display-date');
            var i;
            for (i = 0; i < x.length; i++) {
              x[i].style.transition = '0.25s';
              x[i].style.opacity = 0.05;
            }
          setTimeout( () => {
               this.events = this.structureEvents(this.quarters[--quarter].events);
               setTimeout(() => { //move to bottom of event stack
                   var x = document.getElementsByClassName('display-date');
                     var i;
                     for (i = 0; i < x.length; i++) {
                       x[i].style.transition = '0.25s';
                       x[i].style.opacity = 1;
                     }
                     $('.expanded').css('opacity', 1).show();
               }, 0);

          }, 250);


          setTimeout(() => {
            // show expanded by default
            $('.expanded').css('opacity', 1).show();

            // position underline
            this.positionUnderline();
          }, 0);
      },
      toggleCategoryExpand: function(event, category) {


          this.categoryStructure[category].expanded = !this.categoryStructure[category].expanded;
          var newCategoryStructure = this.categoryStructure;
          this.categoryStructure = {};
          this.categoryStructure = newCategoryStructure;

          if(this.categoryStructure[category].expanded) {
              setTimeout(function () {
                 $(event.target).next('ul').css('opacity', 0)
                .slideDown('slow');
                // jquery .delay() isn't working here, using timeout instead
                setTimeout(function () {
                   $(event.target).next('ul').animate(
                      { opacity: 1 },
                      { queue: false, duration: 'slow' }
                    );
                }, 500);
              }, 0);
          } else {
              setTimeout(function () {
                  $(event.target).next('ul').animate(
                   { opacity: 0 },
                   { queue: false, duration: 'fast' }
               ).slideUp('slow');
              }, 0);
          }

          this.determineExpandCollapse();
      },
      toggleNextYearCategoryExpand: function(event, category) {


          this.nextYearCategoryStructure[category].expanded = !this.nextYearCategoryStructure[category].expanded;
          var newCategoryStructure = this.nextYearCategoryStructure;
          this.nextYearCategoryStructure = {};
          this.nextYearCategoryStructure = newCategoryStructure;

          if(this.nextYearCategoryStructure[category].expanded) {
              setTimeout(function () {
                 $(event.target).next('ul').css('opacity', 0)
                .slideDown('slow');
                // jquery .delay() isn't working here, using timeout instead
                setTimeout(function () {
                   $(event.target).next('ul').animate(
                      { opacity: 1 },
                      { queue: false, duration: 'slow' }
                    );
                }, 500);
              }, 0);
          } else {
              setTimeout(function () {
                  $(event.target).next('ul').animate(
                   { opacity: 0 },
                   { queue: false, duration: 'fast' }
               ).slideUp('slow');
              }, 0);
          }

          this.determineExpandCollapse();
      }
  }

}
</script>