<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>
  <div>
    <visitor-identification />
    <div class="site-wrap">
      <div><placeholder name="jss-main" :rendering="route" /></div>
    </div>

  </div>

</template>

<script>
import { defineComponent } from 'vue';
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import Navigation from './Navigation';
import Footer from './Footer';
import VisitorIdentification from './VisitorIdentification';
import { useMeta } from 'vue-meta';
// import 'bootstrap/dist/css/bootstrap.css';
import './assets/app.css';
import './assets/main.css';

export default defineComponent({
  name: 'Layout',
  props: {
    route: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    useMeta({
      title:
        (props.route.fields &&
          props.route.fields.pageTitle &&
          props.route.fields.pageTitle.value) ||
        'Page',
      meta: [ { name: 'description', content: (props.route.fields && props.route.fields.pageDescription && props.route.fields.pageDescription.value), id: 'desc' }],
      htmlAttrs: {
        lang: 'en'
      }
    });
  },
  components: {
    Placeholder,
    Navigation,
    Footer,
    VisitorIdentification,
  },
});
</script>
