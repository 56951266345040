<template>
  <div class="wrapper">
    <div class="scaffold">
        <sc-text :field="fields.heading" />
    </div>
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'Scaffold',
  components: {
    ScText: Text,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style scoped>
    .scaffold {
        height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f1f2f6;
        font-size: 3em;
        font-weight: bold;
    }
</style>
