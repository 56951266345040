<template>
  <div id="homepage-layout">
      <div><Placeholder name="p1" :rendering="rendering" /></div>
      <div class="header-flex wrapper">
          <div class="flex-1">
			<div>
              <Placeholder name="p2" :rendering="rendering" />
			</div>
          </div>
          <div class="flex-2"></div>
		  <div class="image">
			<Placeholder name="p3" :rendering="rendering" />
		  </div>
      </div>
      <div><Placeholder name="p4" :rendering="rendering" /></div>
      <div class="why-flex wrapper">
          <div class="flex-1">
			<div class="image">
              <Placeholder name="p5" :rendering="rendering" />
			</div>
          </div>
          <div class="flex-2">
			<div>
              <Placeholder name="p6" :rendering="rendering" />
			</div>
          </div>
      </div>
      <div class="industry-flex wrapper">
          <div class="flex-1">
			<div>
              <Placeholder name="p7" :rendering="rendering" />
			</div>
          </div>
          <div class="flex-2">
			<div class="image">
              <Placeholder name="p8" :rendering="rendering" />
			</div>
          </div>
      </div>
  </div>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import Scroll from './Scroll';

export default {
  extends: Scroll,
  name: 'HomepageLayout',
  components: {
    Placeholder
  },
  props: {
      rendering: {
        type: Object,
        default: () => ({}),
      },
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {

  }
}
</script>
