<template>
  <div class="wrapper" id="twitter-feed">
      <h2>Around the industry</h2>
      <p>Highlights from the worlds of music, digital media, and more.</p>
      <div id="tweets">

          <a v-for="(tweet,index) in tweets" v-bind:key="index" :id="`tweet-${index}`" :href="tweet.tweet_url" target="_blank" class="tweet" v-bind:class="{ full_image: tweet.imageOnly }">
              <div class="tweet-header">
              <svg class="twitter-icon" width="22px" height="18px" viewBox="0 0 22 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <!-- Generator: Sketch 63.1 (92452) - https://sketch.com -->
                  <title>twitter</title>
                  <desc>Created with Sketch.</desc>
                  <g id="Get-Resources" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Get-Resources-(Past-Dates)" transform="translate(-134.000000, -2949.000000)">
                          <g transform="translate(119.000000, 2934.000000)">
                              <path d="M354.585786,1 L1,1 L1,439 L379,439 L379,25.4142136 L354.585786,1 Z" id="card" stroke="#1B75BB" stroke-width="2" transform="translate(190.000000, 220.000000) scale(1, -1) translate(-190.000000, -220.000000) "></path>
                              <path d="M37,17.1226562 C36.1903553,17.4717773 35.3109137,17.7231445 34.4035533,17.8208984 C35.3388325,17.2623047 36.0507614,16.3825195 36.3857868,15.3351563 C35.5203046,15.8518555 34.5431472,16.2289063 33.5241117,16.4244141 C32.7005076,15.5446289 31.5279188,15 30.2296954,15 C27.7309645,15 25.7208122,17.0249023 25.7208122,19.5106445 C25.7208122,19.8597656 25.7626904,20.2088867 25.8324873,20.544043 C22.0913706,20.3485352 18.7550761,18.5610352 16.535533,15.8239258 C16.1446701,16.4942383 15.9213198,17.2623047 15.9213198,18.1001953 C15.9213198,19.6642578 16.7170051,21.0467773 17.9314721,21.8567383 C17.1916244,21.8288086 16.4936548,21.6193359 15.893401,21.2841797 L15.893401,21.3400391 C15.893401,23.5325195 17.4428934,25.3479492 19.5088832,25.7668945 C19.1319797,25.8646484 18.7271574,25.9205078 18.322335,25.9205078 C18.0291878,25.9205078 17.75,25.8925781 17.4708122,25.8506836 C18.0431472,27.6381836 19.7043147,28.9369141 21.6865482,28.9788086 C20.1370558,30.19375 18.1967005,30.905957 16.0888325,30.905957 C15.7119289,30.905957 15.3629442,30.8919922 15,30.8500977 C16.9961929,32.1348633 19.3692893,32.875 21.9238579,32.875 C30.215736,32.875 34.7525381,26.0042969 34.7525381,20.0413086 C34.7525381,19.8458008 34.7525381,19.650293 34.7385787,19.4547852 C35.6180203,18.8124023 36.3857868,18.0164062 37,17.1226562 Z" id="twitter---FontAwesome-Copy-3" fill="#1B75BB"></path>
                          </g>
                      </g>
                  </g>
              </svg>
              <p>{{tweet.text}}</p>
            </div>
              <div class="image-wrapper" v-if="tweet.meta_info.media_url">
                  <img v-if="tweet.meta_info.media_url" :src="tweet.meta_info.media_url">

                  <svg class="play-icon" v-if="tweet.media.type == 'video'" height="64" viewBox="0 0 54 64" width="54" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path id="a" d="m190 174 30 50h-60z"/><filter id="b" height="116%" width="113.3%" x="-3.3%" y="-8%"><feOffset dx="2" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1"/><feColorMatrix in="shadowBlurOuter1" type="matrix" values="0 0 0 0 0.105882353   0 0 0 0 0.458823529   0 0 0 0 0.733333333  0 0 0 0.345334353 0"/></filter></defs><g fill="none" fill-rule="evenodd" transform="matrix(0 1 -1 0 226 -160)"><use fill="#000" filter="url(#b)" xlink:href="#a"/><use fill="#fff" fill-rule="evenodd" xlink:href="#a"/></g></svg>
              </div>
              <h3 v-if="tweet.meta_info.title" v-html="tweet.meta_info.title"></h3>
              <p v-if="tweet.meta_info.description" v-html="tweet.meta_info.description"></p>
              <div class="link-only" v-if="tweet.linkOnly"><span>{{tweet.meta_info.linked_url}}</span></div>
          </a>
      </div>
      <div class="pagination">
          <span class="dot" v-for="(tweet, index) in tweets" v-bind:key="index" v-bind:class="{ selected: selectedIndex == index }" @click="scrollIntoView(index)"></span>
      </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";

export default {
  name: 'TwitterFeed',
  data: function() {
    return {
        tweets: null,
        selectedIndex: 0
    }
  },
  mounted () {
    axios
      .get(`/api/twitter/feed?count=12&expanded=true`)
      .then((res) => {
          //console.log(res);
          var mappedTweets = res.data.map(tweet => {

             // Make sure tweet object is built

             tweet.text = tweet.text || "";
             tweet.meta_info = tweet.meta_info || {};
             tweet.meta_info.title = tweet.meta_info.title || "";
             tweet.meta_info.description = tweet.meta_info.description || "";
             tweet.meta_info.media_url = tweet.meta_info.media_url || null;
             tweet.meta_info.linked_url = tweet.meta_info.linked_url || null;
             tweet.media = tweet.media || {};
             tweet.media.type = tweet.media.type || "";
             tweet.media.media_url = tweet.media.media_url || null;

             if(!tweet.meta_info.media_url && tweet.media.media_url) {
                 tweet.meta_info.media_url = tweet.media.media_url;
             }

             if(tweet.meta_info.media_url != null && (!tweet.meta_info.media_url.startsWith("http") || !tweet.meta_info.media_url.startsWith("https")))
             {
                tweet.meta_info.media_url = "https://" + tweet.meta_info.media_url;
             }

             if(tweet.text) {
                tweet.text = (tweet.text.length > 70) ? tweet.text.slice(0, 70) + "..." : tweet.text;
             }
             if(typeof tweet.tweet_url == 'undefined') {
                tweet.tweet_url = "";
             }
             if(typeof tweet.meta_info.title !== 'undefined') {
                tweet.meta_info.title = (tweet.meta_info.title.length > 70) ? tweet.meta_info.title.slice(0, 70) + "..." : tweet.meta_info.title;
             }
             if(tweet.meta_info.description) {
                tweet.meta_info.description = (tweet.meta_info.description.length > 120) ? tweet.meta_info.description.slice(0, 120) + "..." : tweet.meta_info.description;
             }

             // if only the image exists
             if(!tweet.meta_info.title && !tweet.meta_info.description && tweet.meta_info.media_url) {
                 tweet.imageOnly = true;
             }
             // if only the link exists
             if(!tweet.meta_info.title && !tweet.meta_info.description && !tweet.meta_info.media_url && tweet.meta_info.linked_url) {
                 tweet.linkOnly = true;
             }

            return tweet;
        });

        //console.log(this.teamPlaceholder);

        let touchstartX = 0;
        let touchstartY = 0;
        let touchendX = 0;
        let touchendY = 0;

        const gestureZone = document.getElementById('tweets');

        gestureZone.addEventListener('touchstart', function(event) {
            touchstartX = event.changedTouches[0].screenX;
            touchstartY = event.changedTouches[0].screenY;
        }, false);

        gestureZone.addEventListener('touchend', function(event) {
            touchendX = event.changedTouches[0].screenX;
            touchendY = event.changedTouches[0].screenY;
            handleGesture();
        }, false);
        var vm = this;
        function handleGesture() {
            if (touchendX <= touchstartX) {
                //console.log('Swiped left');
                if(vm.selectedIndex !== vm.tweets.length - 1) {
                    vm.selectedIndex++;
                    vm.scrollIntoView(vm.selectedIndex);

                }
            }
            if (touchendX >= touchstartX) {
                //console.log('Swiped right');
                //console.log('Swiped left');
                if(vm.selectedIndex !== 0) {
                    vm.selectedIndex--;
                    vm.scrollIntoView(vm.selectedIndex)

                }
            }
        }

        this.tweets = mappedTweets.slice(0, 3);
      })
  },
  methods: {
      scrollIntoView: function(index) {
          this.selectedIndex = index;
          //console.log('scroll to index', index);
          //console.log(document.getElementById(`tweet-${index}`));
          $('#tweets').animate({scrollLeft: document.getElementById(`tweet-${index}`).offsetLeft - 20}, 800);
      }
  }

}
</script>
