<template>
	<div class="process-card-wrap closed" :id="`process-card-${params.index}`">
		<div class="process-card">
			<img
				  :alt="fields.image.value.alt"
				  :src="fields.image.value.src" />
			<sc-text class="heading" :field="fields.heading" />
			<sc-content class="content" :field="fields.content" />
			<a @click="open" class="purple-link toggle-show more">+ More</a>
			<a @click="close" class="purple-link toggle-show less">- Less</a>
		</div>
	</div>
</template>


<script>
import { RichText } from '@sitecore-jss/sitecore-jss-vue';

// import $ from 'jquery';

export default {
  name: 'ProcessCard',
  components: {
    ScText: RichText,
    ScContent: RichText,
  },
  props: {
	index: {
	  type: Number,
	  default: () => (0),
	},
    fields: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
    }
  },
  data: function() {
    return {
        isOpen: false,
    }
  },
  created: function () {
	//
  },
   methods: {
       open: function() {
           //this.$root.$emit('open-card', this.params.index);
		   this.emitter.emit('open-card', this.params.index);
           // $(e.target).siblings('.content').css('opacity', 0)
           //    .slideDown('slow',function(){
           //        $(e.target).siblings('.content').animate(
           //          { opacity: 1 },
           //          { queue: false, duration: 'slow' }
           //        );
           //   });


       },
       close: function() {
           //this.$root.$emit('open-card', -1);
		   this.emitter.emit('open-card', -1);
            // $(e.target).siblings('.content').animate({ opacity: 0 },{ queue: false, duration: 'slow', complete: ()=>{
            //
            //     $(e.target).siblings('.content').slideUp('slow');
            //     this.$root.$emit('open-card', -1);
            // } });
       }
   }
}
</script>
