<template>
  <div class="button-wrap">
  <a :class="params.color" class="button" :href="fields.url.value.href">
    {{fields.url.value.text}}
  </a>
</div>
</template>

<script>
import { Text, Link } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'Button',
  components: {
    ScText: Text,
    ScLink: Link
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
    }
    },
    created() {
        // this.fields.url.value.href
    },
}
</script>
