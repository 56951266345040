<template>
    <img class="image-component"
        :alt="fields.image.value.alt"
        :src="fields.image.value.src" />
</template>

<script>

export default {
  name: 'SwImage',
  components: {},
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
