<template>
  <div v-if="isVisible" id="announcement-bar">
      <div class="wrapper">
          <sc-text :field="fields.content" />
      </div>
  </div>
</template>

<script>
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import moment from "moment";

export default {
  name: 'AnnouncementBar',
  components: {
    ScText: RichText,
  },
  data: function() {
    return {
        isVisible: false
    }
},
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
      var startDate = this.fields.startDate.value;
      var endDate = this.fields.endDate.value;
      this.isVisible = moment(new Date()).isBetween(startDate, endDate)
  },
}
</script>
