<template>
  <div id="tools-layout">
      <div class="header-flex wrapper image-under-title">
          <div class="flex-1">
              <div><Placeholder name="p1" :rendering="rendering" /></div>
          </div> <div class="image"><Placeholder name="p3" :rendering="rendering" /></div>
          <div class="flex-2"><div><Placeholder name="p2" :rendering="rendering" /></div></div>

      </div>
       <div><Placeholder name="p4" :rendering="rendering" /></div>
       <div class="flexbox wrapper find-royalties">
           <div class="flex-1">
               <div class="image"><Placeholder name="p5" :rendering="rendering" /></div>
           </div>
           <div class="flex-2">
               <div><Placeholder name="p6" :rendering="rendering" /></div>
           </div>
       </div>
       <div class="more-ascap">
           <div class="ribbon-single-top"></div>
           <div class="ribbon-single-bottom"></div>
           <div class="ribbon-top"><div class="ribbon-kink"></div></div>
           <div class="ribbon-bottom"><div class="ribbon-kink"></div></div>
       <div class="flexbox wrapper">
           <div class="flex-1">
               <div><Placeholder name="p7" :rendering="rendering" /></div>
           </div>
           <div class="ribbon-mobile-divider"><div class="ribbon-kink"></div></div>
           <div class="flex-2">
               <div><Placeholder name="p8" :rendering="rendering" /></div>
           </div>
       </div>
   </div>
       <div><Placeholder name="p9" :rendering="rendering" /></div>
  </div>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import Scroll from './Scroll';

export default {
  extends: Scroll,
  name: 'ToolsLayout',
  components: {
    Placeholder
  },
  props: {
      rendering: {
        type: Object,
        default: () => ({}),
      },
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
