<template>
  <div class="link-content-block">
  <sc-text tag="h2" :field="fields.heading" />
  <sc-rich-text tag="p" :field="fields.content" />
    <sc-link :field="fields.url" />
  </div>
</template>

<script>
import { Text, RichText, Link } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'LinkContentBlock',
  components: {
    ScText: Text,
    ScRichText: RichText,
    ScLink: Link,
    ScLinkText: Text,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
