<template>
    <div id="footer">
        <div class="wrapper">
            <div class="top-footer">
                <div class="footer-left">
                    <div class="social-links">

                        <!-- twitter -->
                        <a title="Songwize Twitter" aria-label="Songwize Twitter" href="https://twitter.com/songwize" target="_blank"><svg height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" transform="translate(1 1)"><path d="m26 9.9296875c-.7360406.3173828-1.535533.5458984-2.3604061.6347656.8502538-.5078125 1.4974619-1.30761716 1.8020305-2.2597656-.7868021.46972656-1.6751269.8125-2.6015229.99023438-.7487309-.79980469-1.8147208-1.29492188-2.9949238-1.29492188-2.2715736 0-4.0989848 1.84082031-4.0989848 4.1005859 0 .3173829.0380711.6347657.1015228.9394532-3.4010152-.1777344-6.43401012-1.8027344-8.45177661-4.29101566-.35532995.609375-.55837564 1.30761716-.55837564 2.06933596 0 1.421875.72335026 2.6787109 1.82741117 3.415039-.67258883-.0253906-1.3071066-.2158203-1.85279188-.5205078v.0507813c0 1.993164 1.40862944 3.6435547 3.28680206 4.024414-.34263962.0888672-.71065993.1396485-1.07868023.1396485-.26649746 0-.52030457-.0253906-.77411168-.0634766.52030457 1.625 2.03045681 2.8056641 3.83248731 2.84375-1.4086294 1.1044922-3.17258883 1.7519531-5.08883248 1.7519531-.3426396 0-.65989848-.0126953-.98984772-.0507812 1.81472081 1.1679687 3.97208122 1.8408203 6.2944162 1.8408203 7.5380711 0 11.6624366-6.2460938 11.6624366-11.6669922 0-.1777344 0-.3554687-.0126904-.5332031.7994924-.5839844 1.497462-1.3076172 2.0558376-2.1201172z" fill="#00b3be" class="fill-change"/><circle cx="15" cy="15" r="15" stroke="#ffffff" class="stroke-change"/></g></svg></a>

                        <!-- facebook -->
                        <a title="Songwize Facebook" aria-label="Songwize Facebook" href="https://www.facebook.com/songwize/" target="_blank"><svg height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" transform="translate(-756 -944)"><circle cx="772" cy="960" r="15" stroke="#ffffff" class="stroke-change"/><path d="m782 960.061113c0-5.556603-4.477148-10.061113-10-10.061113s-10 4.50451-10 10.061113c0 5.021793 3.656855 9.184107 8.4375 9.938887v-7.030596h-2.539062v-2.908291h2.539062v-2.216589c0-2.521566 1.49293-3.914402 3.777148-3.914402 1.094082 0 2.238477.196507 2.238477.196507v2.475977h-1.260977c-1.242246 0-1.629648.77555-1.629648 1.571204v1.887303h2.773438l-.44336 2.908291h-2.330078v7.030596c4.780645-.75478 8.4375-4.917094 8.4375-9.938887" fill="#00b3be" class="fill-change"/></g></svg></a>

                        <!-- linkedin -->
                        <a title="Songwize LinkedIn" aria-label="Songwize LinkedIn" href="https://www.linkedin.com/showcase/songwize/" target="_blank"><svg height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" transform="translate(-971 -944)"><path d="m983.864782 967.4571c0 .299848-.241237.5429-.538845.5429h-2.293739c-.297608 0-.538845-.243052-.538845-.5429v-9.687592c0-.299847.241237-.5429.538845-.5429h2.293739c.297608 0 .538845.243053.538845.5429zm-1.685714-11.143692c-1.203455 0-2.179068-.982953-2.179068-2.195461s.975613-2.195461 2.179068-2.195461c1.203454 0 2.179067.982953 2.179067 2.195461s-.975559 2.195461-2.179067 2.195461zm13.672731 11.187422c0 .275661-.221842.49917-.495444.49917h-2.461351c-.273602 0-.495442-.223509-.495442-.49917v-4.54403c0-.677868.197352-2.970453-1.758269-2.970453-1.516923 0-1.824606 1.569205-1.886389 2.273419v5.241064c0 .275661-.221786.49917-.495442.49917h-2.380545c-.273603 0-.495442-.223509-.495442-.49917v-9.775052c0-.275661.221839-.49917.495442-.49917h2.380545c.273603 0 .495442.223509.495442.49917v.845176c.562477-.850467 1.398395-1.506902 3.178205-1.506902 3.941247 0 3.91869 3.709813 3.91869 5.74817z" fill="#00b3be" class="fill-change"/><circle cx="987" cy="960" r="15" stroke="#fffffff" class="stroke-change white-stroke"/></g></svg></a>
                    </div>
                    <router-link to="/get-in-touch">
                      Get In Touch
                    </router-link><br>
                    <a href="#">ASCAP Member Access</a>
                </div>
            </div>
            <nav class="bottom-footer">
                <a href="#">Legal Terms</a>
                <a href="#">Privacy Policy</a>
                <a href="#">Cookie Policy</a>
                <a href="#">Cookie Preference Center</a>
            </nav>
            <img :src="logoImg" class="logo" alt="Songwize | Powered by ASCAP" >
        </div>
    </div>
</template>

<script>
import logo from './assets/songwize_powered_by_ascap_logo.svg';

export default {
  name: 'Navigation',
  data() {
    return {
      logoImg: logo,
    };
  },
  created() {

  },

  methods: {

  },
  components: {
    // Button
  }
};
</script>
