<template>
    <router-link class="nav-link" :to="fields.internalLink.value.href" :class="fields.internalLink.value.class">{{fields.internalLink.value.text}}</router-link>

</template>

<script>
import { Link } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'NavLink',
  components: {
      SwLink: Link
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
      params: {
        type: Object,
      },
    },
  },
}
</script>
