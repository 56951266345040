<template>
  <div id="who-we-are-layout">
      <div class="header-flex wrapper image-under-title">
          <div class="flex-1">
              <div><Placeholder name="p1" :rendering="rendering" /></div>
          </div>
          <div class="image"> <Placeholder name="p3" :rendering="rendering" /> </div>
          <div class="flex-2"> <div><Placeholder name="p2" :rendering="rendering" /></div></div>

      </div>
      <div class="wrapper mobile-full-width">
          <div><Placeholder name="p4" :rendering="rendering" /></div>
      </div>
    <div class="our-clients-container">
          <div class="ribbon-top"><div class="ribbon-top-inside"></div></div>

          <div class="flexbox wrapper client-logos-container">
              <div class="flex-1">
                  <div><Placeholder name="p5" :rendering="rendering" /></div>
              </div>
              <div class="flex-2">
                  <div class="client-logos"><Placeholder name="p6" :rendering="rendering" /></div>
              </div>
          </div>

          <div class="ribbon-bottom"><div class="ribbon-bottom-inside"></div></div>
    </div>
      <div class="flexbox wrapper get-in-touch-container">
          <div class="flex-1">
              <div class="colorbox">
                  <div><Placeholder name="p7" :rendering="rendering" /></div>
              </div>
          </div>
          <div class="flex-2">
              <div class="image"><Placeholder name="p8" :rendering="rendering" /></div>
          </div>
      </div>
  </div>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import Scroll from './Scroll';

export default {
  extends: Scroll,
  name: 'WhoWeAreLayout',
  components: {
    Placeholder
  },
  props: {
      rendering: {
        type: Object,
        default: () => ({}),
      },
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
