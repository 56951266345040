<template>
    <div id="calendar" class="wrapper">
        <div v-for="(quarter, index) in quarters" v-bind:key="index" class="calendar-pdf-block">
        <div class="calendar-header">
            <h2 class="year" v-if="year">Songwize Schedule {{year}}</h2>
            <div class="quarter-buttons">
                <span class="quarter-btn">Q{{index+1}}</span>
            </div>
        </div>

        <ul v-for="(e, index) in Math.ceil(quarter.events.length / maxRows)" v-bind:key="index">
            <li v-for="(event, i) in quarter.events.slice((e - 1) * maxRows, e * maxRows)" v-bind:key="i" v-bind:class="{deadline: event.is_deadline, distribution: event.is_distribution }" >
                <h4 class="display-date"  v-bind:class="{full_opacity: !shouldAnimate}">{{event.humanDate}}</h4>
                <span v-if="event.is_deadline" class="type">DEADLINE
                    <img src="/data/media/img/deadline.svg">
                </span>
                <span v-if="event.is_distribution" class="type">DISTRIBUTION
                    <img src="/data/media/img/distribution.svg">
                </span>
                <p v-if="event.subtitle" class="subtitle">{{event.subtitle}}</p>
                <p>{{event.title}} <span class="teal-text" v-if="event.quarterYear">{{event.quarterYear}}</span></p>
                <div class="bar"></div>
                <div class="bar-combiner" v-if="i !== 0 && !event.isStart"></div>
                <div class="category" v-if="i == 0 || event.isStart">{{event.category}}</div>
            </li>
            <!-- Fills out the grid with empty cells for formatting -->
            <li class="empty" v-if="index == Math.ceil(quarter.events.length / maxRows) - 1 && (Math.ceil(quarter.events.length / maxRows) - 1) && maxRows - Math.ceil(quarter.events.length % maxRows) !== maxRows" v-for="x in maxRows - Math.ceil(quarter.events.length % maxRows)" v-bind:key="x">
            </li>
        </ul>

        </div>

    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: 'SwCalendarRow',
  data: function() {
    return {
        events: [],
        categoryStructure: {},
        quarters: [],
        activeQuarter: 1,
        year: null,
        isMobile: false,
        maxRows: 4,
        shouldAnimate: false,
        showExpandAll: true,
        test: null
    }
  },
  created () {
      var res = [
          {
              "year": "2020",
              "quarters": [
                  {
                      "events": [
                          {
                              "title": "Publisher &/or DSP notifies Songwize of new distribution needed this quarter",
                              "subtitle": "Test Subtitle",
                              "outline": "",
                              "content": "October, November & December 2018 (4Q18)",
                              "location": "",
                              "address": "",
                              "city": "",
                              "state": "",
                              "start_time": "",
                              "end_time": "",
                              "is_deadline": false,
                              "is_non_ascap_event": false,
                              "is_distribution": true,
                              "start_date": "2020-01-03T05:00:00Z",
                              "end_date": "2020-09-09T05:00:00Z",
                              "category": "Data Delivery",
                              "display_date": "Display Date",
                              "Status": "Ok",
                              "quarterYear": "Hello World"
                          },
                          {
                              "title": "Foo",
                              "subtitle": "Bar",
                              "outline": "",
                              "content": "",
                              "location": "",
                              "address": "",
                              "city": "",
                              "state": "",
                              "start_time": "",
                              "end_time": "",
                              "is_deadline": false,
                              "is_non_ascap_event": false,
                              "is_distribution": false,
                              "start_date": "2020-01-17T05:00:00Z",
                              "end_date": "2020-09-09T05:00:00Z",
                              "category": "Data Delivery",
                              "display_date": "",
                              "Status": "Ok"
                          },
                          {
                              "title": "Q1 Event 2",
                              "subtitle": "",
                              "outline": "",
                              "content": "",
                              "location": "",
                              "address": "",
                              "city": "",
                              "state": "",
                              "start_time": "",
                              "end_time": "",
                              "is_deadline": false,
                              "is_non_ascap_event": false,
                              "is_distribution": false,
                              "start_date": "2020-02-20T05:00:00Z",
                              "end_date": "2020-09-09T05:00:00Z",
                              "category": "Data Resolution",
                              "display_date": "",
                              "Status": "Ok"
                          },
                          {
                              "title": "Q1 Event 3",
                              "subtitle": "",
                              "outline": "",
                              "content": "",
                              "location": "",
                              "address": "",
                              "city": "",
                              "state": "",
                              "start_time": "",
                              "end_time": "",
                              "is_deadline": false,
                              "is_non_ascap_event": false,
                              "is_distribution": false,
                              "start_date": "2020-02-03T05:00:00Z",
                              "end_date": "2020-09-09T05:00:00Z",
                              "category": "Data Resolution",
                              "display_date": "",
                              "Status": "Ok"
                          },
                          {
                              "title": "Q1 Event 3",
                              "subtitle": "",
                              "outline": "",
                              "content": "",
                              "location": "",
                              "address": "",
                              "city": "",
                              "state": "",
                              "start_time": "",
                              "end_time": "",
                              "is_deadline": false,
                              "is_non_ascap_event": false,
                              "is_distribution": false,
                              "start_date": "2020-03-03T05:00:00Z",
                              "end_date": "2020-09-09T05:00:00Z",
                              "category": "Invoicing + Payment",
                              "display_date": "",
                              "Status": "Ok"
                          }
                      ],
                      "Status": "Ok"
                  },
                  {
                      "events": [
                          {
                              "title": "Q2 Event 1",
                              "subtitle": "",
                              "outline": "",
                              "content": "",
                              "location": "",
                              "address": "",
                              "city": "",
                              "state": "",
                              "start_time": "",
                              "end_time": "",
                              "is_deadline": false,
                              "is_non_ascap_event": false,
                              "is_distribution": false,
                              "start_date": "2020-04-09T05:00:00Z",
                              "end_date": "2020-09-09T05:00:00Z",
                              "category": "Data Delivery",
                              "display_date": "",
                              "Status": "Ok"
                          },
                          {
                              "title": "Q2 Event 2",
                              "subtitle": "",
                              "outline": "",
                              "content": "",
                              "location": "",
                              "address": "",
                              "city": "",
                              "state": "",
                              "start_time": "",
                              "end_time": "",
                              "is_deadline": false,
                              "is_non_ascap_event": false,
                              "is_distribution": false,
                              "start_date": "2020-04-09T05:00:00Z",
                              "end_date": "2020-09-09T05:00:00Z",
                              "category": "Data Delivery",
                              "display_date": "",
                              "Status": "Ok"
                          },
                          {
                              "title": "Publisher &/or DSP notifies Songwize of new distribution needed this quarter",
                              "subtitle": "Test Subtitle",
                              "outline": "",
                              "content": "October, November & December 2018 (4Q18)",
                              "location": "",
                              "address": "",
                              "city": "",
                              "state": "",
                              "start_time": "",
                              "end_time": "",
                              "is_deadline": false,
                              "is_non_ascap_event": false,
                              "is_distribution": true,
                              "start_date": "2020-05-09T05:00:00Z",
                              "end_date": "2020-09-09T05:00:00Z",
                              "category": "Data Resolution",
                              "display_date": "User Defined",
                              "Status": "Ok"
                          },
                          {
                              "title": "Q1 Event 3",
                              "subtitle": "",
                              "outline": "",
                              "content": "",
                              "location": "",
                              "address": "",
                              "city": "",
                              "state": "",
                              "start_time": "",
                              "end_time": "",
                              "is_deadline": false,
                              "is_non_ascap_event": false,
                              "is_distribution": false,
                              "start_date": "2020-05-03T05:00:00Z",
                              "end_date": "2020-09-09T05:00:00Z",
                              "category": "Data Resolution",
                              "display_date": "",
                              "Status": "Ok"
                          },
                          {
                              "title": "Q1 Event 3",
                              "subtitle": "",
                              "outline": "",
                              "content": "",
                              "location": "",
                              "address": "",
                              "city": "",
                              "state": "",
                              "start_time": "",
                              "end_time": "",
                              "is_deadline": false,
                              "is_non_ascap_event": false,
                              "is_distribution": false,
                              "start_date": "2020-06-03T05:00:00Z",
                              "end_date": "2020-09-09T05:00:00Z",
                              "category": "Invoicing + Payment",
                              "display_date": "",
                              "Status": "Ok"
                          }
                      ],
                      "Status": "Ok"
                  },
                  {
                      "events": [
                          {
                              "title": "Q3 Event 1",
                              "subtitle": "",
                              "outline": "",
                              "content": "",
                              "location": "",
                              "address": "",
                              "city": "",
                              "state": "",
                              "start_time": "",
                              "end_time": "",
                              "is_deadline": false,
                              "is_non_ascap_event": false,
                              "is_distribution": false,
                              "start_date": "2020-07-09T05:00:00Z",
                              "end_date": "2020-09-09T05:00:00Z",
                              "category": "Data Delivery",
                              "display_date": "",
                              "Status": "Ok"
                          },
                          {
                              "title": "Q3 Event 2",
                              "subtitle": "",
                              "outline": "",
                              "content": "",
                              "location": "",
                              "address": "",
                              "city": "",
                              "state": "",
                              "start_time": "",
                              "end_time": "",
                              "is_deadline": false,
                              "is_non_ascap_event": false,
                              "is_distribution": false,
                              "start_date": "2020-07-09T05:00:00Z",
                              "end_date": "2020-09-09T05:00:00Z",
                              "category": "Data Delivery",
                              "display_date": "",
                              "Status": "Ok"
                          },
                          {
                              "title": "Q3 Event 3",
                              "subtitle": "",
                              "outline": "",
                              "content": "",
                              "location": "",
                              "address": "",
                              "city": "",
                              "state": "",
                              "start_time": "",
                              "end_time": "",
                              "is_deadline": false,
                              "is_non_ascap_event": false,
                              "is_distribution": false,
                              "start_date": "2020-08-09T05:00:00Z",
                              "end_date": "2020-09-09T05:00:00Z",
                              "category": "Data Resolution",
                              "display_date": "",
                              "Status": "Ok"
                          },
                          {
                              "title": "Q1 Event 3",
                              "subtitle": "",
                              "outline": "",
                              "content": "",
                              "location": "",
                              "address": "",
                              "city": "",
                              "state": "",
                              "start_time": "",
                              "end_time": "",
                              "is_deadline": false,
                              "is_non_ascap_event": false,
                              "is_distribution": false,
                              "start_date": "2020-08-03T05:00:00Z",
                              "end_date": "2020-09-09T05:00:00Z",
                              "category": "Data Resolution",
                              "display_date": "",
                              "Status": "Ok"
                          },
                          {
                              "title": "Q1 Event 3",
                              "subtitle": "",
                              "outline": "",
                              "content": "",
                              "location": "",
                              "address": "",
                              "city": "",
                              "state": "",
                              "start_time": "",
                              "end_time": "",
                              "is_deadline": false,
                              "is_non_ascap_event": false,
                              "is_distribution": false,
                              "start_date": "2020-09-03T05:00:00Z",
                              "end_date": "2020-09-09T05:00:00Z",
                              "category": "Invoicing + Payment",
                              "display_date": "",
                              "Status": "Ok"
                          }
                      ],
                      "Status": "Ok"
                  },
                  {
                      "events": [
                          {
                              "title": "Q4 Event 1",
                              "subtitle": "",
                              "outline": "",
                              "content": "",
                              "location": "",
                              "address": "",
                              "city": "",
                              "state": "",
                              "start_time": "",
                              "end_time": "",
                              "is_deadline": false,
                              "is_non_ascap_event": false,
                              "is_distribution": false,
                              "start_date": "2020-10-09T05:00:00Z",
                              "end_date": "2020-09-09T05:00:00Z",
                              "category": "Data Delivery",
                              "display_date": "",
                              "Status": "Ok"
                          },
                          {
                              "title": "Q4 Event 2",
                              "subtitle": "",
                              "outline": "",
                              "content": "",
                              "location": "",
                              "address": "",
                              "city": "",
                              "state": "",
                              "start_time": "",
                              "end_time": "",
                              "is_deadline": false,
                              "is_non_ascap_event": false,
                              "is_distribution": false,
                              "start_date": "2020-10-09T05:00:00Z",
                              "end_date": "2020-09-09T05:00:00Z",
                              "category": "Data Delivery",
                              "display_date": "",
                              "Status": "Ok"
                          },
                          {
                              "title": "Q4 Event 3",
                              "subtitle": "",
                              "outline": "",
                              "content": "October, November & December 2018 (4Q18)",
                              "location": "",
                              "address": "",
                              "city": "",
                              "state": "",
                              "start_time": "",
                              "end_time": "",
                              "is_deadline": false,
                              "is_non_ascap_event": false,
                              "is_distribution": true,
                              "start_date": "2020-11-09T05:00:00Z",
                              "end_date": "2020-09-09T05:00:00Z",
                              "category": "Data Resolution",
                              "display_date": "",
                              "Status": "Ok"
                          },
                          {
                              "title": "Q1 Event 3",
                              "subtitle": "",
                              "outline": "",
                              "content": "",
                              "location": "",
                              "address": "",
                              "city": "",
                              "state": "",
                              "start_time": "",
                              "end_time": "",
                              "is_deadline": false,
                              "is_non_ascap_event": false,
                              "is_distribution": false,
                              "start_date": "2020-11-03T05:00:00Z",
                              "end_date": "2020-09-09T05:00:00Z",
                              "category": "Data Resolution",
                              "display_date": "",
                              "Status": "Ok"
                          },
                          {
                              "title": "Q1 Event 3",
                              "subtitle": "",
                              "outline": "",
                              "content": "",
                              "location": "",
                              "address": "",
                              "city": "",
                              "state": "",
                              "start_time": "",
                              "end_time": "",
                              "is_deadline": false,
                              "is_non_ascap_event": false,
                              "is_distribution": false,
                              "start_date": "2020-12-03T05:00:00Z",
                              "end_date": "2020-09-09T05:00:00Z",
                              "category": "Invoicing + Payment",
                              "display_date": "",
                              "Status": "Ok"
                          }
                      ],
                      "Status": "Ok"
                  }
              ],
              "Status": "Ok"
          },
          {
              "year": "2021",
              "quarters": [
                  {
                      "events": [
                          {
                              "title": "Blah",
                              "subtitle": "Blerg",
                              "outline": "",
                              "content": "",
                              "location": "",
                              "address": "",
                              "city": "",
                              "state": "",
                              "start_time": "",
                              "end_time": "",
                              "is_deadline": false,
                              "is_non_ascap_event": false,
                              "is_distribution": false,
                              "start_date": "2021-01-03T05:00:00Z",
                              "end_date": "2020-09-09T05:00:00Z",
                              "category": "Data Delivery",
                              "display_date": "",
                              "Status": "Ok"
                          },
                          {
                              "title": "Foo",
                              "subtitle": "Bar",
                              "outline": "",
                              "content": "",
                              "location": "",
                              "address": "",
                              "city": "",
                              "state": "",
                              "start_time": "",
                              "end_time": "",
                              "is_deadline": false,
                              "is_non_ascap_event": false,
                              "is_distribution": false,
                              "start_date": "2021-01-17T05:00:00Z",
                              "end_date": "2020-09-09T05:00:00Z",
                              "category": "Data Delivery",
                              "display_date": "",
                              "Status": "Ok"
                          },
                          {
                              "title": "Q1 Event 2",
                              "subtitle": "",
                              "outline": "",
                              "content": "",
                              "location": "",
                              "address": "",
                              "city": "",
                              "state": "",
                              "start_time": "",
                              "end_time": "",
                              "is_deadline": false,
                              "is_non_ascap_event": false,
                              "is_distribution": false,
                              "start_date": "2021-02-20T05:00:00Z",
                              "end_date": "2020-09-09T05:00:00Z",
                              "category": "Data Delivery",
                              "display_date": "",
                              "Status": "Ok"
                          },
                          {
                              "title": "Q1 Event 3",
                              "subtitle": "",
                              "outline": "",
                              "content": "",
                              "location": "",
                              "address": "",
                              "city": "",
                              "state": "",
                              "start_time": "",
                              "end_time": "",
                              "is_deadline": false,
                              "is_non_ascap_event": false,
                              "is_distribution": false,
                              "start_date": "2021-03-03T05:00:00Z",
                              "end_date": "2020-09-09T05:00:00Z",
                              "category": "Data Delivery",
                              "display_date": "",
                              "Status": "Ok"
                          }
                      ],
                      "Status": "Ok"
                  }
              ],
              "Status": "Ok"
          }
      ];

      this.quarters = res[0].quarters;
      this.year = res[0].year;

      // get active quarter by checking if the last date in quarter is after today's date
      for (var i = 0; i < this.quarters.length; i++) {
          this.activeQuarter = i+1;
          var lastEvent = this.quarters[i].events[this.quarters[i].events.length - 1];
          var isAfter = moment(lastEvent.start_date).isAfter(new Date());


          if(isAfter) break;
      }
       for (var i = 0; i < this.quarters.length; i++) {
            this.quarters[i].events = this.structureEvents(this.quarters[i].events);
       }


  },
  methods: {

      structureEvents(events) {
          this.categoryStructure = {};

          var nextEvent = null;

          var structuredEvents = events.map(event => {
              event.humanDate = event.display_date || moment(event.start_date).format('MMMM D');
              event.hasPassed = moment(event.start_date).isBefore(new Date())
              event.isEnd = false;
              event.isFirst = false;
              if(event.is_distribution) {
                  var quarterYear = event.content.match(/\(([^)]+)\)/)[1]
                  if(quarterYear) {
                      var q = quarterYear.slice(0, 2)
                      //console.log(q);
                      q = q.split("").reverse().join("");
                      var y = "20" + quarterYear.slice(2, 4);
                      //console.log("YEAR",y);
                      quarterYear = `[${q} ${y}]`
                      event.quarterYear = quarterYear;
                  }
              }
              return event;
          });

          // TODO: Refactor this
          var itemsInRow = this.maxRows;

          var previousCategory = "Process"; // use to group in category if none exists
          for (var i = 0; i < events.length; i++) {

              //console.log('loop');

              // set current category to previous category if none exists
              events[i].category = events[i].category || previousCategory;
              previousCategory = events[i].category;
              //console.log('previous category',previousCategory);

              //console.log('category',events[i].category);

              if(typeof this.categoryStructure[events[i].category] == 'undefined') {
                  this.categoryStructure[events[i].category] = {};
                  this.categoryStructure[events[i].category].events = [];
                  this.categoryStructure[events[i].category].expanded = false;
              }
              this.categoryStructure[events[i].category].events.push(events[i]);

              if(!nextEvent && moment(events[i].start_date).isAfter(new Date())) {
                  nextEvent = events[i];
                  //console.log('NEXT EVENT: ',nextEvent);
              } else {
                  //console.log('we have the next event',  nextEvent);
                  //console.log(events[i].start_date);
                  //console.log('isAfter',  moment(events[i].start_date).isAfter(new Date()));
              }

              if(i < events.length-1) {
                  if(typeof events[i+1].category == 'undefined') {
                       //console.log("UNDEFINED");
                       events[i+1].category = events[i].category;
                  }
                  if(events[i].category !== events[i+1].category) {
                      //console.log("UNDEFINED 2");
                    events[i].isEnd = true;
                  }
              } else {
                  //console.log("UNDEFINED 3");
                  events[i].isEnd = true;
              }
              if(i > 0) {
                  if(events[i].category !== events[i-1].category) {
                    events[i].isStart = true;
                  }
              }
          }
          if(nextEvent) {
              this.categoryStructure[nextEvent.category].expanded = true;
          }

          for(var key in this.categoryStructure) {
              var categoryEvents = this.categoryStructure[key].events;
              if(moment(categoryEvents[categoryEvents.length - 1].start_date).isBefore(new Date())){
                  this.categoryStructure[key].hasPassed = true;
              } else {
                  this.categoryStructure[key].hasPassed = false;
              }
              //console.log('loop');
          }

          return structuredEvents;
      }
  }

}
</script>
<style scoped>
#calendar .calendar-pdf-block {
    /* height: 100vh !important; */
    margin-bottom: 80px
}
</style>
