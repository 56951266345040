<template>
    <div>
        <h2 id="form-title"  v-if="!formsent" >Send us a message</h2>
        <p v-if="!formsent">*Required items</p>
        <div id="form-sent"  v-if="formsent">
            <h2>Thanks for getting in touch! The SONGWIZE team will get back to you within three business days.</h2>
            <span>In the meantime, check out SONGWIZE on <a href="https://twitter.com/songwize" target="blank">Twitter</a>.</span>
        </div>
        <form v-else action="#" id="my_captcha_form" novalidate>

            <div class="group">
                <input v-model="name" name="name" type="text" id="name" required>
                <label for="name">Name*</label>
                <span v-if="errors.name" class="error">{{errors.name}}</span>
            </div>

            <div class="group">
                <input v-model="email" name="email" type="text" id="email" required>
                <label for="email">Email address*</label>
                <span v-if="errors.email" class="error">{{errors.email}}</span>
            </div>
            <div class="group">
                <textarea v-model="message" name="message" id="message" class="form-control" required/>
                <label for="message">How can we help you?<span class="toggle-show"> If you're an ASCAP member, please provide your member ID and anything else you think we need to know.</span>*</label>
                <span v-if="errors.message" class="error">{{errors.message}}</span>
            </div>


            <div class="group">
                <vue-recaptcha sitekey="6LckRuAUAAAAAJVgfGB5KbvKHgGM9fJlryQFfgSP" ref="recaptcha"
                @verify="onVerify"
                @expired="onExpired" :loadRecaptchaScript="true"></vue-recaptcha>
                <span v-if="errors.captcha" class="error">{{errors.captcha}}</span>
            </div>
            <a @click="send" type="submit" class="button blue">Submit</a>
        </form>
    </div>
</template>

<script>
import axios from "axios";
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: 'ContactForm',
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data: function() {
    return {
        email: "",
        name: "",
        message: "",
        recaptcha: "",
        errors: {},
        formsent: false
    }
  },
  methods: {
    onVerify: function(response) {
        this.recaptcha = response;
    },
    onExpired:function() {
        this.recaptcha = "";
        //console.log('expired');
    },
   send: function() {
       axios({
             url: '/api/contactform/Submit',
             method: 'POST',
             data: {
                 Email: this.email,
                 Name: this.name,
                 Message: this.message,
                 Captcha: this.recaptcha
             }
         }).then(result => {
             var data = result.data;
             if ('errors' in data) {
                 this.errors = data.errors;
                 return false;
             }
             this.formsent = true;

         });
       this.$refs.recaptcha.reset();
     }
 },
 components: { VueRecaptcha }
}

</script>
