<template>
  <div class="jss-loading" v-if="visible">
      <img :src="loadingImg" alt="Loading">
  </div>
</template>

<script>
import loading from './assets/loading.gif';
export default {
  data() {
    return {
      visible: false,
      loadingImg: loading,
    };
  },
  props: {
    delay: {
      type: Number,
      default: 300,
    },
  },
  created() {
    // Wait {this.delay}ms before making the loading graphic visible.
    // If whatever other async operation completes within that timeframe,
    // then whichever parent component invoked the loading component will
    // presumably re-render without the loading component.
    // This helps prevent the loading graphic from too briefly "flashing" on the screen
    // causing an undesirable flicker for users.
    setTimeout(() => {
      this.visible = true;
    }, this.delay);
  },
};
</script>

<style scoped>
.jss-loading {
  display: block;
  position: absolute;
  width: 100vw;
  height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3em;
  font-weight: bold;
}


</style>
