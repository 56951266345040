<template>
  <div :class="params.color">
    <sw-heading tag="h3" :field="fields.heading" />
    <div class="ribbon-underline"></div>
    <sw-content :field="fields.content" />
    <a :href="fields.url.value">
      <sw-link-text :field="fields.linkText" />
    </a>
  </div>
</template>

<script>
import { RichText, Text, Link } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'ColorContentBlock',
  components: {
    SwHeading: RichText,
    SwContent: RichText,
    SwLink: Link,
    SwLinkText: Text,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({

      }),
    },
    params: {
      type: Object,
    },
  },
}
</script>

<style scoped>
.ribbon-underline {
    color: #fff;
    background: blue;
    display: block;
    text-align: center;
    height: 12px;
    width: 80%;
    background: #0491B2;
    background:
    linear-gradient(-225deg,transparent 0,#0491B2 0) left /50.5% 100%,
    linear-gradient( 315deg,transparent 20px,#0491B2 0) top right/50.5% 100%;
    background-repeat:no-repeat;
}
a {
    font-weight: bold;
    text-decoration: none;
}
.teal a {
    color: #0491B2;
}
.teal .ribbon-underline {
    background: #0491B2;
    background:
    linear-gradient(-225deg,transparent 0,#0491B2 0) left /50.5% 100%,
    linear-gradient( 315deg,transparent 20px,#0491B2 0) top right/50.5% 100%;
    background-repeat:no-repeat;
}
.purple a {
    color: #90278E;
}
.purple .ribbon-underline {
    background: #90278E;
    background:
    linear-gradient(-225deg,transparent 0,#90278E 0) left /50.5% 100%,
    linear-gradient( 315deg,transparent 20px,#90278E 0) top right/50.5% 100%;
    background-repeat:no-repeat;
}

</style>
