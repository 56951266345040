<template>
     <sw-link-component :field="fields.url" />
</template>

<script>
import { Link } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'SwLink',
  components: {
      SwLinkComponent: Link
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
      params: {
        type: Object,
      },
    },
  },
}
</script>
