<template>
	<div id="process-card-container">
		<div class="flexbox process-cards">
             <component :is="processCard" v-for="(processCard, index) in $options.computed.processCardsPlaceholder"  :key="`processCard${index}`" :index="index"/>
		</div>
	</div>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import $ from 'jquery';

export default {
  name: 'ProcessCardContainer',
  data: function() {
    return {
        selectedIndex: 0,
        openIndex: -1
    }
  },
  components: {},
  props: {
    rendering: {
      type: Object,
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
    }
  },
  mounted(){
	
    this.emitter.on('open-card', (index) => {
        //console.log('calling open card');
        if(index !== -1) {
            $(`.process-card-wrap:not(process-card-` + index + `)`).removeClass('open').addClass('closed');


            if(!$(`#process-card-` + index).hasClass('open')) {
                $(`#process-card-` + index).removeClass('closed').addClass('open');
            }
            // $(`#process-card-${index}`).removeClass('closed').addClass('open');
        } else {
            $(`.process-card-wrap.open`).addClass('closed').removeClass('open');
        }
        $(`.process-card-wrap.closed .content`).animate(
          { opacity: 0 },
          { queue: false, duration: 'fast' }
		).slideUp('slow');

        if(index !== -1) {
            $(`#process-card-` + index + ` .content`).css('opacity', 0)
          .slideDown('slow');
          // jquery .delay() isn't working here, using timeout instead
          setTimeout(function () {
              $(`#process-card-` + index + ` .content`).animate(
                { opacity: 1 },
                { queue: false, duration: 'slow' }
              );
          }, 500);
        }

        this.openIndex = index;
    });
  },
  withPlaceholder() {
    return {
      placeholders: [
        {
          placeholder: 'process-cards',
          computedPropName: 'processCardsPlaceholder',
        },
      ],
    };
  },
}
</script>
