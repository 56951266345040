<template>
  <div id="what-we-do-layout">
      <div class="header-flex wrapper image-under-title">
          <div class="flex-1">
              <div><Placeholder name="p1" :rendering="rendering" /></div>
          </div>
          <div class="image"><Placeholder name="p3" :rendering="rendering" /></div>
          <div class="align-process-container">
              <div class="flex-2"> <div><Placeholder name="p2" :rendering="rendering" /></div></div>
              <div class="process-container">
                  <div><Placeholder name="p4" :rendering="rendering" /></div>
              </div>
            </div>
      </div>

      <div id="powered-by" class="powered-by">
          <div class="wrapper">
          <div class="ribbon-top"><div class="ribbon-kink"></div></div>
          <div class="ribbon-bottom"><div class="ribbon-kink"></div></div>
          <div class="ribbon-right"></div>
          <div class="ribbon-left"></div>

          <div class="flexbox">
              <div class="flex-1">
                  <div class="image"><Placeholder name="p5" :rendering="rendering" /></div>
              </div>
              <div class="flex-2">
                  <div><Placeholder name="p6" :rendering="rendering" /></div>
              </div>
          </div>
           <div class="flex-columns"><Placeholder name="p7" :rendering="rendering" /></div>
      </div>

    </div>
    <div class="flexbox wrapper smaller-h2 your-music">
        <div class="flex-1">
            <div><Placeholder name="p8" :rendering="rendering" /></div>
            <div class="colorbox">
                <div><Placeholder name="p9" :rendering="rendering" /></div>
            </div>
        </div>
        <div class="flex-2">
            <div class="image"><Placeholder name="p10" :rendering="rendering" /></div>
        </div>
    </div>
  </div>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import Scroll from './Scroll';

export default {
  extends: Scroll,
  name: 'WhatWeDoLayout',
  components: {
    Placeholder
  },
  props: {
      rendering: {
        type: Object,
        default: () => ({}),
      },
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
