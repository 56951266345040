export default {
  created() {
    if (typeof window !== 'undefined') {
      var wheelEventsValues = this.createWheelEventValues();
      window.addEventListener(wheelEventsValues[0], this.preventDefault, wheelEventsValues[1]);
      window.addEventListener('DOMMouseScroll', this.preventDefault, false);
      window.addEventListener('scroll', this.preventDefault, false);
      window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
    }
  },
  destroyed() {
    if (typeof window !== 'undefined') {
      var wheelEventsValues = this.createWheelEventValues();
      window.removeEventListener(wheelEventsValues[0], this.preventDefault, wheelEventsValues[1]);
      window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
      window.removeEventListener('scroll', this.preventDefault, false);
      window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
    }
  },
  methods: {
    createWheelEventValues() {
      // Chromium requires passive for event listeners
      var supportsPassive = false;
      try {
        window.addEventListener(
          'passiveQuery',
          null,
          Object.defineProperty({}, 'passive', {
            get: function() {
              supportsPassive = true;
              return true;
            },
          })
        );
      } catch (e) {
        //console.log('exception getting passive');
      }

      var wheelOpt = supportsPassive ? { passive: false } : false;
      var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

      return [wheelEvent, wheelOpt];
    },
    preventDefault(e) {
      e.preventDefault();
      window.scrollBy(0, e.deltaY);
    },
    preventDefaultForScrollKeys(e) {
      var keys = { 37: 1, 39: 1 };
      if (keys[e.keyCode]) {
        this.preventDefault(e);
        return false;
      }
    },
  },
};
