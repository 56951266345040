<template>
  <div id="get-in-touch-layout">
      <div class="flexbox-header wrapper">
          <div class="flex-1">
              <div><Placeholder name="p1" :rendering="rendering" /></div>
          </div>
          <div class="flex-2">

          </div>
          <div class="image"><Placeholder name="p2" :rendering="rendering" /></div>
      </div>
      <div class="flexbox wrapper">
          <div class="flex-1">
              <div><Placeholder name="p3" :rendering="rendering" /></div>
          </div>
          <div class="flex-2">
              <div class="colorbox purple">
                  <div><Placeholder name="p4" :rendering="rendering" /></div>
            </div>
            <div><Placeholder name="p5" :rendering="rendering" /></div>
          </div>
      </div>
  </div>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'GetInTouchLayout',
  components: {
    Placeholder
  },
  props: {
      rendering: {
        type: Object,
        default: () => ({}),
      },
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style scoped>

</style>
