<template>
  <div id="address-container">
    <sw-heading tag="h3" :field="fields.heading" />
    <sw-content :field="fields.content" />
    <a :href="fields.mapUrl.value.href" :title="fields.mapUrl.value.title" :target="fields.mapUrl.value.target">
        <img
            :alt="fields.image.value.alt"
            :src="fields.image.value.src" />
        <sw-location class="address" :field="fields.location" />
    </a>
  </div>
</template>

<script>
import { RichText, Link } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'SwAddress',
  components: {
    SwLink: Link,
    SwHeading: RichText,
    SwContent: RichText,
    SwLocation: RichText,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
